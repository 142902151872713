/* eslint-disable */
import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import style from "./style.module.css"

const NavigationItem = ({ index, id, text }) => (
  <li key={index} className={style.items}>
    <Link to={'#' + id} className={style.link}>
      {text}
      <span className={style.indicator}>{index}</span>
    </Link>
  </li>
)

NavigationItem.propTypes = {
  index: PropTypes.number,
  text: PropTypes.string,
}

NavigationItem.defaultProps = {
  text: "",
  index: 0,
}

const navigationEntries = [
  {
    index: 1,
    text: "Apresentação",
    id: 'introduction'
  },
  {
    index: 2,
    text: "Quero me cadastrar",
    id: 'register'
  },
]
const Navigation = () => (
  <ul className={style.navigation}>{navigationEntries.map(NavigationItem)}</ul>
)

export { Navigation }
