/**
 * dependencies
 */
import React from "react"
import PropTypes from "prop-types"
import style from "./style.module.css"
import classNames from "classnames"

const Container = ({ children, classes }) => (
  <div className={classNames([style.container, classes])}>{children}</div>
)

Container.propTypes = {
  children: PropTypes.node.isRequired,
  classes: PropTypes.string,
}

Container.defaultProps = {
  classes: "",
}

export { Container }
