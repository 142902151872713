/* eslint-disable */
import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import { Container } from "../container"
import style from "./style.module.css"
import { Navigation } from "../navigation"

const Header = ({ siteTitle }) => (
  <header className={style.header}>
    <Container classes={style.container}>
      <h1 className={style.title}>
        <Link className={style.link} to="/">
          {siteTitle}
          <span className={style.divider}></span>
        </Link>
      </h1>
      <Navigation />
      <button className={style.regulations}>Regulamento</button>
    </Container>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export { Header }
